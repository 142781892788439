<template>
<transition name="fadePage" mode="out-in">
<div class="myAssets">
  <Top-Nav></Top-Nav>
  <div class="common_title">{{$t('menu.wdzc')}}</div>
  <div class="lab_container">
    <div class="sec_desc">
      <div class="l_sec"><img src="../../../public/images/suanli.png" alt=""></div>
      <div class="r_sec">
        <div class="num_sec">{{(userMintInfo._pow>0?14400-formatAmountMethod(userMintInfo._pow):0) | numFormat2}}</div>
        <div class="nam_sec"><span>{{$t('menu.residualComPower')}}</span></div>
      </div>
    </div>
    <div class="sec_desc">
      <div class="l_sec"><img src="../../../public/images/suanli.png" alt=""></div>
      <div class="r_sec">
        <div class="num_sec">{{formatAmountMethod(userMintInfo._pow)}}</div>
        <div class="nam_sec">
          <router-link :to="{name:'ComputingPower',path:'/computingPower'}">
            <span>{{$t('menu.yccsl')}}</span><img src="../../../public/images/right.png" alt="">
          </router-link>
        </div>
      </div>
    </div>
  </div> 
  <div class="change_container">
      <!-- STAR -->
      <div class="swiper-slide-my">
        <div class="box">
          <div class="pic_b"><img :src="star.img" alt=""></div>
          <div class="center_cont">
            <div class="cout_num">{{star.total_num}}</div>
            <div class="cout_name">
              <router-link :to="{name:'AssetDetails',path:'/assetDetails',params: {type:star.tpye}}">
                <span>{{$t('menu.'+star.total_name)}}</span><img src="../../../public/images/right.png" alt="">
              </router-link>
            </div>
          </div>
          <div class="bottom_cont">
            <div>
              <div class="num_tq">{{formatAmountMethod(userMintInfo._waitReceiveStar)}}</div>
              <div class="num_tq_name">{{$t('menu.miningBeExtracted')}}</div>
              <div class="num_tq_btn"><button :disabled="btn1" @click="withdrawSwapRewardClick" :class="{disabled_btn:btn1}">{{$t('menu.extract')}}</button></div>
            </div>
            <div>
              <div class="num_tq">{{formatAmountMethod(userNodeInfo._waitReceiveStar)}}</div>
              <div class="num_tq_name">{{$t('menu.nodeBeExtracted')}}</div>
              <div class="num_tq_btn"><button :disabled="btn2" @click="withdrawNodeRewardClick" :class="{disabled_btn:btn2}">{{$t('menu.extract')}}</button></div>
            </div>
            <div>
              <div class="num_tq">{{formatAmountMethod(userMintInfo._alreadyRecieveStar)+formatAmountMethod(userNodeInfo._alreadyRecieveStar)}}</div>
              <div class="num_tq_name">{{$t('menu.extracted')}}</div>
              <!-- <div class="num_tq_btn"><a href="javascript:;" @click="approveStar" :class="{disabled_a:isDisabl_a}">{{$t('menu.grantAuthorization')}}</a></div> -->
            </div>
          </div>
        </div>
      </div>
      <!-- ZEED -->
      <div class="swiper-slide-my">
        <div class="box">
        <div class="pic_b"><img :src="zeed.img" alt=""></div>
        <div class="center_cont">
          <div class="cout_num">{{zeed.total_num}}</div>
          <div class="cout_name">
            <router-link :to="{name:'AssetDetails',path:'/assetDetails',params: {type:zeed.tpye}}">
              <span>{{$t('menu.'+zeed.total_name)}}</span><img src="../../../public/images/right.png" alt="">
            </router-link>
          </div>
        </div>
         <div class="bottom_cont">
          <div>
            <div class="num_tq">{{formatAmountMethod(userRefInfo._waitReceiveSeed)}}</div>
            <div class="num_tq_name">{{$t('menu.toBeExtracted')}}</div>
            <div class="num_tq_btn"><button :disabled="btn3" @click="refWithdrawClick" :class="{disabled_btn:btn3}">{{$t('menu.extract')}}</button></div>
          </div>
          <div>
            <div class="num_tq">{{formatAmountMethod(userRefInfo._alreadyRecieveSeed)}}</div>
            <div class="num_tq_name">{{$t('menu.extracted')}}</div>
          </div>
        </div>
        </div>
      </div>
      <!-- YEED -->
      <!-- <div class="swiper-slide-my">
        <div class="box">
        <div class="pic_b"><img :src="yeed.img" alt=""></div>
        <div class="center_cont">
          <div class="cout_num">{{yeed.total_num}}</div>
          <div class="cout_name">
            <router-link :to="{name:'AssetDetails',path:'/assetDetails',params: {type:yeed.tpye}}">
              <span>{{$t('menu.'+yeed.total_name)}}</span><img src="../../../public/images/right.png" alt="">
            </router-link>
          </div>
        </div>
         <div class="bottom_cont">
          <div>
            <div class="num_tq">{{formatAmountMethod(userRefInfo._waitReceiveSeed)}}</div>
            <div class="num_tq_name">{{$t('menu.toBeExtracted')}}</div>
            <div class="num_tq_btn"><button :disabled="btn4" :class="{disabled_btn:btn4}">{{$t('menu.extract')}}</button></div>
          </div>
          <div>
            <div class="num_tq">---</div>
            <div class="num_tq_name">{{$t('menu.extracted')}}</div>
          </div>
        </div>
        </div>
      </div> -->
  </div>

  <el-dialog :title="dialogTitle" :visible.sync="isShowDialog" :close-on-click-modal="false" top="30vh" width="400px">
  <el-form :inline="true" class="deleteForm">
    <el-switch
      v-model="dialogWithdrawType"
      active-text="具体金额"
      inactive-text="提取全部">
    </el-switch>
    <el-form-item label="提取具体金额" v-if="dialogWithdrawType">
       <!-- <el-input v-model="beginWorker" autocomplete="off" placeholder="请输入登记者的名字"></el-input> -->
       <el-input-number v-model="dialogWithdrawNum" :precision="5" :min="0" :step="0.1" label="描述文字"></el-input-number>
    </el-form-item>
    
   </el-form>
   <div slot="footer" class="dialog-footer">
     <el-button size="small" @click="isShowDialog = false">取 消</el-button>
     <el-button size="small" type="primary" @click="doWithdraw">确 定</el-button>
   </div>
  </el-dialog>

</div>
</transition>
</template>
<script>
import '../../style/base.css';
import '../../style/myAssets.css';
import TopNav from '../../components/common/TopNav.vue';
// import Swiper from 'swiper'; // 注意引入的是Swiper

const starPool = require('../../utils/starPool')

export default {
  name: 'MyAssets',
  components:{TopNav},
  data() {
    return {
      btn1:false,
      btn2:false,
      btn3:false,
      btn4:false,
      formatAmountMethod:starPool.amountConversion,
      // isDisabl_a:false,//禁用a标签
      residualComPower:0,//剩余算力
      computingPower:0,//已产出算力
      swiperOption: {
        pagination: {
          paginationClickable:true,
          slidesPerView:'auto',
          spaceBetween:30,
          centeredSlides:true,
          effect:'coverflow'
        }
      },
      star:{
        img:require("../../../public/images/icon-Star-zc.png"),
        total_num:"0",
        total_name:"totalAssets_star",
        tpye:1,
      },
      zeed:{
        img:require("../../../public/images/icon-Zeed-zc.png"),
        total_num:"0",
        total_name:"totalAssets_zeed",
        tpye:2,
      },
      yeed:{
        img:require("../../../public/images/icon-Yeed-zc.png"),
        total_num:"0",
        total_name:"totalAssets_yeed",
        tpye:3,
      },
      //推荐奖励信息
      userRefInfo:{
        _refCount:0, // 一级推荐人数
        _alreadyRecieveSeed:0, // 累计已领取ZEED数量
        _alreadyRecieveSeedToUsdt:0, // 折合XXX USDT
        _waitReceiveSeed:0, // 累计未领取ZEED数量
        _waitReceiveSeedToUsdt:0 // 折合xx USDT
      },
      // 用户挖矿数据：（累计已领取start数量，折合XXX USDT,累计未领取star数量，折合xx USDT）
      userMintInfo:{
        _pow:0,//用户算力
        _alreadyRecieveStar:0,
        _alreadyRecieveStarToUsdt:0,
        _waitReceiveStar:0,
        _waitReceiveStarToUsdt:0,
      },
      // 节点数据：（累计已领取start数量，折合XXX USDT,累计未领取star数量，折合xx USDT）
      userNodeInfo:{
        _isNode:0,//是否是节点
        _alreadyRecieveStar:0,
        _alreadyRecieveStarToUsdt:0,
        _waitReceiveStar:0,
        _waitReceiveStarToUsdt:0,
        _newPow:0,//节点算力
      },
      isShowDialog:false,
      dialogTitle:'提现',// 弹窗标题
      dialogType:'',// 弹窗类型
      dialogWithdrawType:false, // 提现类型，false:提取全部，true:具体金额
      dialogWithdrawNum:0,// 提现具体金额

    }
  },
  created:function(){
    // localStorage.setItem('address', '0x3037383BF37964411EF6dc781Bdb20eCFfd4e36c') //切换账号
    // 资产查询
    let that = this
    // 获取STAR余额
    starPool.getStarTokenBalance().then(res=>{
      that.star.total_num = res
    })
    // 获取ZEED余额
    starPool.getSeedTokenBalance().then(res=>{
      that.zeed.total_num = res
    })

    //推荐奖励信息
    starPool.userRefInfo().then(res=>{
      that.userRefInfo = res
    })

    // 用户挖矿数据
    starPool.userMintInfo().then(res=>{
      that.userMintInfo = res
    })
    // 节点数据
    starPool.userNodeInfo().then(res=>{
      that.userNodeInfo = res
    })
  },
  mounted() {
		
	},
  methods:{
    extract(){
      // this.$toast("提取成功");
    },
    // 展示提现弹窗
    showDialog(title,type){
      console.log(title,type)
      this.dialogWithdrawType = false
      this.dialogWithdrawNum = 0
      this.dialogTitle =  title
      this.dialogType = type

      this.isShowDialog = true
      
    },
    // 执行提现
    doWithdraw(){
      console.log(this.dialogTitle,this.dialogWithdrawNum)
      switch (this.dialogType) {
        case 'withdrawSwapReward':
          this.withdrawSwapReward()
          break;
        case 'withdrawNodeReward':
          this.withdrawNodeReward()
          break;
        case 'refWithdraw':
          this.refWithdraw()
          break;
        default:
          this.$toast('没有对应的方法');
          break;
      }
      this.isShowDialog = false
    },
    withdrawSwapRewardClick(){
      this.showDialog('算力奖励提现','withdrawSwapReward')
    },
    withdrawNodeRewardClick(){
      this.showDialog('节点奖励提现','withdrawNodeReward')
    },
    refWithdrawClick(){
      this.showDialog('推荐奖励提现','refWithdraw')
    },
    
    // 价格都传0
    /**
     * 算力奖励提现
     */
    withdrawSwapReward(){
      let that = this
      that.btn1 = true
    
      // let _amount =starPool.amountConversion(this.userMintInfo._waitReceiveStar)
      let _amount = this.dialogWithdrawNum
      console.log('算力奖励提现数量',_amount);
      starPool.withdrawSwapReward(_amount.toString()).then(res=>{
        that.btn1 = false;
        this.$toast(this.$t('menu.extractionSucceeded'));
        console.log('算力奖励提现res',res);
      })
    },
    //节点奖励提现
    withdrawNodeReward(){
      let that = this
      that.btn2 = true

      // let _amount =starPool.amountConversion(this.userNodeInfo._waitReceiveStar)
      let _amount = this.dialogWithdrawNum
      console.log('节点奖励提现数量',_amount);
      starPool.withdrawNodeReward(_amount.toString()).then(res=>{
        console.log('节点奖励提现res',res);
        this.$toast(this.$t('menu.extractionSucceeded'));
        that.btn2 = false
      })
    },
    //提现推荐奖励
    refWithdraw(){
      let that = this
      that.btn3 = true;
      // let _amount =starPool.amountConversion(this.userRefInfo._waitReceiveSeed)
      let _amount = this.dialogWithdrawNum
      console.log('提现推荐奖励数量',_amount);
      starPool.refWithdraw(_amount.toString()).then(res=>{
        console.log('提现推荐奖励res',res);
        // that.btn3 = false;
        this.$toast(this.$t('menu.extractionSucceeded'));
      })
    },
    // 授权STAR
    // approveStar(){
    //   let that = this
    //   that.isDisabl_a = true
    //   starPool.approveStar().then(res => {
    //     console.log(res)
    //     that.isDisabl_a = false
    //   })
    // }

  }
}
</script>
<style scoped>
@media screen and (max-width: 500px) {
   .el-dialog__wrapper .el-dialog {
      width: 300px !important;
    }
    .el-dialog__wrapper .el-dialog .el-dialog__body{
      padding: 10px 20px!important;
    }
    .el-dialog__wrapper .el-dialog .el-dialog__body .el-form-item__label{
      width: 68px!important;
    }
    .el-dialog__wrapper .el-dialog .el-dialog__body .el-select,.el-input{
      width: 180px!important;
    }
}
</style>